<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha">Segmentos</h2>
      </div>
      <div class="col-md-2 tbl-cadastro" v-if="situacaoAno && acesso.cadastrar">
        <pm-Button
          type="button"
          style="float: right; margin-top: 30px"
          @click="registar()"
          label="CADASTRAR SEGMENTO"
          class="p-button-success"
          icon="pi pi-external-link"
          title="CADASTRAR SEGMENTO"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="seguimentoEscolarALL"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="flex justify-content-center align-items-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <pm-InputText
                style="height: 34px !important;"
                v-model="pesquisa"
                @input="pesquisar"
                placeholder="Pesquisar Nome do Segmento"
              />
            </span>
          </div>
        </template>
        <pm-Column
          field="nome"
          sortable
          header="NOME DO SEGMENTO"
          class="colunn-tbl"
        >
        </pm-Column>
        <pm-Column
          field="qdt_alunos"
          header="QTDE DE ALUNOS"
          class="colunn-tbl"
        >
        </pm-Column>

        <pm-Column
          field="id"
          headerStyle="width: 4rem; text-align: center"
          class="colunn-tbl2"
        >
          <template #body="{ data }">
            <pm-Button
              type="button"
              @click="editarSegmento(data.id)"
              icon="pi pi-pencil"
              label="Editar"
              title="Editar"
              v-if="situacaoAno && acesso.editar"
              class="p-button-sm btn-color table_style"
            ></pm-Button>
            <pm-Button
              title="Apagar"
              v-if="situacaoAno && acesso.deletar"
              type="button"
              @click="deletarSegmento(data.id)"
              label="Apagar"
              icon="pi pi-times-circle"
              class="p-button-danger p-button-sm btn-color table_style"
              style="margin-left: 5px"
            ></pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages" style="display: inline-flex">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
        <selectPorPag qdt="20" @changePagina="changePagina"></selectPorPag>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Verificar } from "@/class/verificar.js";
import selectPorPag from "@/components/selectPorPag.vue";
import pagina from "@/class/pagina";

export default defineComponent({
  components: {
    selectPorPag
  },
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "id", label: "Ação" },
    ];

    return {
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Segmentos" }],
      filter: "",
      situacaoAno: 1,
      seguimentoEscolarALL: [],
      links: [],

      columns,
      perPage: 20,
      currentPage: 1,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
    };
  },
  computed: {
    pages() {
      if (this.perPage && this.perPage !== "0") {
        return Math.ceil(this.seguimentoEscolarALL.total / this.perPage);
      } else {
        return this.seguimentoEscolarALL.total;
      }
    },
  },
  methods: {
    changePagina(pg){
      pagina.routePg = "&pg="+pg
      pagina.pg = pg
      this.listarTudo()
    },
    editarSegmento(id) {
      this.$router.push({
        name: "coordenacao-segmento-edit",
        params: { id: id },
      });
    },
    async paginas(n) {
      if (n.url != null) {
        if (!n.active) {
          const routePg = pagina.routePg;
          const res = await axios.get(n.url+routePg);
          this.seguimentoEscolarALL = [];
          this.seguimentoEscolarALL = res.data.data;
          this.links = res.data.links;
        }
      }
    },

    async deletarSegmento(id) {
      try {
        if (
          window.confirm(
            "Você realmente deseja excluir o segmento selecionado?"
          )
        ) {
          const data = await SegmentoEscolar.remover(id);
          this.$vaToast.init({
            message: "Apagado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 4000,
            fullWidth: false,
            color: "success",
          });
          this.listarTudo();
        }
      } catch (e) {
        const a = e.response.data.error;
        this.$vaToast.init({
          message: a,
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 4000,
          fullWidth: false,
          color: "danger",
        });
        return false;
      }
    },
    registar() {
      this.$router.push({ name: "coordenacao-segmento-add" });
    },
    async listarTudo() {
      try {
        const data = await SegmentoEscolar.obtemTodosPG(this.currentPage);
        this.seguimentoEscolarALL = data.data.data;
        this.seguimentoEscolarALL.reverse();
        this.links = data.data.links;
      } catch (e) {}
    },
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.listarTudo();
    const acesso = await Verificar.AcessoLocal(3);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
